import { Component } from 'vue-property-decorator'
import { BaseMixin } from 'src/components/mixins/base'

import { SET_META } from 'booksprout-app'

@Component
export default class PrivacyPolicyAllProducts extends BaseMixin {
  created () {
    void this.$store.dispatch(SET_META, {
      title: 'Privacy Policies'
    })
  }
}
